<template>
  <div>
    <div
      v-if="isFetching"
      class="global-spinner"
    >
      <div class="spinner">
        <div class="half" />
      </div>
    </div>
    <div v-else>
      <Form
        class="stacked-form"
        ref="domainsAddForm"
        :model="formValidate"
        :rules="formRules"
        label-position="left"
        :label-width="100"
      >
        <Row
          type="flex"
          :gutter="40"
        >
          <Col
            :xs="24"
            :md="13"
            class="small-widget-col"
          >
            <Row>
              <div class="tile">
                <div class="tile-header tile-header--simple">
                  <span class="tile-header-title">{{ $t('global.domain.add') }}</span>
                </div>
                <div class="tile-block">
                  <FormItem
                    prop="name"
                    label="Name"
                  >
                    <Input
                      type="text"
                      placeholder="Enter the domain name"
                      v-model="formValidate.name"
                    />
                  </FormItem>
                  <FormItem
                    prop="vendor"
                    label="Vendor"
                  >
                    <Select
                      placeholder="Type to search for a vendor by name"
                      filterable
                      remote
                      :remote-method="remoteVendors"
                      v-model="formValidate.vendor"
                    >
                      <Option
                        :value="vendor.id"
                        v-for="vendor in options.vendors"
                        :key="vendor.id"
                      >
                        {{ vendor.name }}
                      </Option>
                    </Select>
                  </FormItem>
                </div>
                <div class="tile-block">
                  <div class="form-actions">
                    <Button
                      type="primary"
                      class="btn btn--elevated"
                      @click="handleSubmit('domainsAddForm')"
                    >
                      {{ $t('global.update') }}
                    </Button>
                    <Button
                      @click="handleReset('domainsAddForm')"
                      type="text"
                    >
                      {{ $t('global.reset') }}
                    </Button>
                  </div>
                </div>
              </div>
            </Row>
          </Col>
        </Row>
      </Form>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AdminUsersView',
  data () {
    return {
      isFetching: false,
      options: {
        vendors: [],
        properties: []
      },
      formValidate: {
        name: null,
        vendor: null,
        properties: []
      },
      formRules: {
        name: [
          {
            type: 'string',
            required: true,
            min: 3,
            whitespace: true,
            message: this.$t('global.validation.char_min'),
            trigger: 'blur'
          }
        ],
        vendor: [
          {
            type: 'integer',
            required: true,
            whitespace: true,
            message: 'Required',
            trigger: 'blur'
          },
          {
            validator (rule, value, callback) {
              if (!value) {
                return callback(new Error('Required'))
              }

              callback()
            }
          }
        ]
      }
    }
  },
  methods: {
    async remoteVendors (q) {
      if (q.length) {
        try {
          const {data: responseData} = await this.$axios.get(`/vendors/remoteSearch`, {
            cache: {
              exclude: {query: true}
            },
            params: {q}
          })

          if (responseData.success) {
            this.options = {
              ...this.options,
              vendors: responseData.widget.vendors
            }
          } else {
            console.error(responseData)
          }
        } catch (err) {
          console.error(err)
        }
      }
    },
    handleReset (name) {
      this.$refs[name].resetFields()
    },
    handleSubmit (name) {
      this.$refs[name].validate(async (valid) => {
        if (valid) {
          this.isUpdating = true

          const payload = {
            ...this.formValidate
          }

          try {
            const responseData = await this.$axios.post(`/users/updatejson/${this.user.id}`, payload)
            this.isUpdating = false
            if (responseData.data.success) {
              this.$Message.success(this.$t('global.validation.changed'))
            } else {
              this.$Message.error(this.$t('global.validation.error'))
            }
          } catch (err) {
            this.isUpdating = false
            this.$Message.error(this.$t('global.validation.serv_error'))
            console.error('handleSubmit', err)
          }
        } else {
          this.$Message.error(this.$t('global.validation.invalid'))
        }
      })
    },
    async fetch () {
      this.isFetching = true
      try {
        const {data: responseData} = await this.$axios.get(`domains/adminDomainsView`, {
          cache: {
            exclude: {query: true}
          },
          params: {
            name: this.$route.query.domain
          }
        })

        if (responseData.success) {
          const {domain, vendors} = responseData.widget
          this.formValidate = {
            name: domain.name,
            vendor: domain.vendorId
          }
          this.options.vendors = vendors
        } else {
          console.log('Server error')
        }
      } catch (err) {
        console.log(err)
      } finally {
        this.isFetching = false
      }
    }
  },
  mounted () {
    this.fetch()
  }
}
</script>

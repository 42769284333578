var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.isFetching ? _c('div', {
    staticClass: "global-spinner"
  }, [_vm._m(0)]) : _c('div', [_c('Form', {
    ref: "domainsAddForm",
    staticClass: "stacked-form",
    attrs: {
      "model": _vm.formValidate,
      "rules": _vm.formRules,
      "label-position": "left",
      "label-width": 100
    }
  }, [_c('Row', {
    attrs: {
      "type": "flex",
      "gutter": 40
    }
  }, [_c('Col', {
    staticClass: "small-widget-col",
    attrs: {
      "xs": 24,
      "md": 13
    }
  }, [_c('Row', [_c('div', {
    staticClass: "tile"
  }, [_c('div', {
    staticClass: "tile-header tile-header--simple"
  }, [_c('span', {
    staticClass: "tile-header-title"
  }, [_vm._v(_vm._s(_vm.$t('global.domain.add')))])]), _c('div', {
    staticClass: "tile-block"
  }, [_c('FormItem', {
    attrs: {
      "prop": "name",
      "label": "Name"
    }
  }, [_c('Input', {
    attrs: {
      "type": "text",
      "placeholder": "Enter the domain name"
    },
    model: {
      value: _vm.formValidate.name,
      callback: function callback($$v) {
        _vm.$set(_vm.formValidate, "name", $$v);
      },
      expression: "formValidate.name"
    }
  })], 1), _c('FormItem', {
    attrs: {
      "prop": "vendor",
      "label": "Vendor"
    }
  }, [_c('Select', {
    attrs: {
      "placeholder": "Type to search for a vendor by name",
      "filterable": "",
      "remote": "",
      "remote-method": _vm.remoteVendors
    },
    model: {
      value: _vm.formValidate.vendor,
      callback: function callback($$v) {
        _vm.$set(_vm.formValidate, "vendor", $$v);
      },
      expression: "formValidate.vendor"
    }
  }, _vm._l(_vm.options.vendors, function (vendor) {
    return _c('Option', {
      key: vendor.id,
      attrs: {
        "value": vendor.id
      }
    }, [_vm._v(" " + _vm._s(vendor.name) + " ")]);
  }), 1)], 1)], 1), _c('div', {
    staticClass: "tile-block"
  }, [_c('div', {
    staticClass: "form-actions"
  }, [_c('Button', {
    staticClass: "btn btn--elevated",
    attrs: {
      "type": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.handleSubmit('domainsAddForm');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('global.update')) + " ")]), _c('Button', {
    attrs: {
      "type": "text"
    },
    on: {
      "click": function click($event) {
        return _vm.handleReset('domainsAddForm');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('global.reset')) + " ")])], 1)])])])], 1)], 1)], 1)], 1)]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "spinner"
  }, [_c('div', {
    staticClass: "half"
  })]);

}]

export { render, staticRenderFns }